<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title class="title">
            Photos
            {{ album_name.length ? " For " + album_name : "" }}

            <v-spacer></v-spacer>
            <add-button @action="form.dialog = true" permission="image-create">
              New Photo
            </add-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pb-0 mb-0">
              <v-flex mb-5 xs6>
                <v-text-field
                  v-model="image_search"
                  label="Search By Image title"
                  outlined
                  dense
                  class="mt-0 mb-0"
                ></v-text-field>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-alert
            v-if="!form.items.data.length && !form.loading"
            :value="true"
            type="error"
          >
            Oops looks like you don't have any images attached to this album({{
              album_name
            }}). You can click
            <a href="" @click.prevent="form.dialog = true">here</a>
            to add photos.
          </v-alert>
          <!-- <v-scroll-y-transition> -->
          <!-- <v-card outlined> -->

          <!-- </v-card> -->
          <!-- </v-scroll-y-transition> -->
          <!-- :headers="headers" -->
          <v-data-table
            :headers="headers"
            :loading="form.loading"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            footer-props.items-per-page-options="rowsPerPageItems"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.title }}</strong>
                </td>
                <td class="text-xs-left">
                  <div
                    @click="imgae_preview()"
                    style="
                                            margin: 5px 0;
                                            border-radius: 50%;
                                            overflow: hidden;
                                            height:50px;
                                            width:50px;
                                            border:2px solid #ccc;
                                        "
                  >
                    <img height="50" width="50" :src="item.image" alt="" />
                  </div>
                </td>
                <td class="text-right">
                  <edit-button
                    permission="grade-update"
                    @agree="form.edit(item), setData()"
                  />
                  <delete-button
                    permission="grade-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>

          <!-- <v-layout style="padding: 0 15px;"  wrap>
                        <v-flex
                                v-for="(item,key) in form.items.data"
                                :key="key"
                                xs3
                                d-flex>
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat tile class="d-flex">
                                    <div style="position:absolute;z-index: 99;right: 10px; bottom:10px">
                                        <delete-button permission="image-delete" @agree="form.delete(item.id)" :outlined="false"/>
                                        <edit-button permission="image-update" @agree="form.edit(item)" :outlined="false"/>
                                    </div>
                                    <v-img
                                            :src="item.image"
                                            :lazy-src="item.image"
                                            aspect-ratio="1"
                                            class="grey lighten-2">
                                        <v-expand-transition>
                                            <div v-if="hover"
                                                 class="d-flex transition-fast-in-fast-out blue darken-2 v-card--reveal display-3 white--text"
                                                 style="height: 100%;">
                                                <div class="album_style_mis">
                                                    <h3>{{item.title.toUpperCase()}}</h3>
                                                    <p>{{item.description}}</p>
                                                </div>
                                            </div>
                                        </v-expand-transition>

                                        <template v-slot:placeholder>
                                            <v-layout
                                                    fill-height
                                                    align-center
                                                    justify-center
                                                    ma-0>
                                                <v-progress-circular indeterminate
                                                                     color="grey lighten-5"></v-progress-circular>
                                            </v-layout>
                                        </template>

                                    </v-img>
                                </v-card>
                            </v-hover>
                        </v-flex>
                    </v-layout> -->

          <!-- <v-card-actions>
                        <v-spacer/>
                        <v-pagination v-if="form.items.data.length > 0 && form.items.meta.total>form.items.perPage" v-model="pagination.page"
                                      :length="form.items.meta.last_page"></v-pagination>
                        <v-spacer/>
                    </v-card-actions> -->
          <!-- ==================== image Preview ================= -->
          <v-dialog
            @keydown.esc="image_preview = false"
            v-model="image_preview"
            persistent
            max-width="1000px"
          >
            <v-card>
              <v-card-title class="primary white--text">
                <span class="title">Image Preview</span>
                <v-spacer></v-spacer>
                <v-icon @click="image_preview = false" style="color: white"
                  >close</v-icon
                >
              </v-card-title>
              <v-card-text class="pa-1">
                <v-carousel>
                  <v-carousel-item
                    v-for="(item, i) in form.items.data"
                    :key="i"
                    :src="item.image"
                  ></v-carousel-item>
                </v-carousel>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- close image preview -->
        </v-card>
      </v-flex>

      <v-dialog
        v-model="form.dialog"
        persistent
        max-width="600px"
        v-if="isEdit"
      >
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Update Photo</span>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="Photo Title*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.title"
                      name="name"
                      outlined
                      dense
                      :error-messages="form.errors.get('title')"
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
                                        <v-textarea
                                                :error-messages="form.errors.get('description')"
                                                v-model="form.description"
                                                name="name"
                                                label="Description"
                                                outlined dense
                                                hint="Short and sweet notice is always eye catching."></v-textarea>
                                    </v-flex> -->
                  <v-flex vx12>
                    <v-text-field
                      label="Select Photo"
                      v-model="imagePicker.name"
                      prepend-inner-icon="add_a_photo"
                      @click="onPickFile"
                      :error-messages="form.errors.get('image')"
                      outlined
                      dense
                      required
                    ></v-text-field>
                    <input
                      type="file"
                      style="display: none"
                      ref="image"
                      accept="image/*"
                      @change="onFilePicked"
                    />
                  </v-flex>
                  <v-flex xs12 v-if="imagePicker.url">
                    <v-card>
                      <v-card-text>
                        <!-- <img :src="imagePicker.url" height="150"/> -->
                        <img
                          :src="imagePicker.url"
                          style="
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    "
                        />
                        <v-btn
                          fab
                          small
                          bottom
                          right
                          absolute
                          @click.native.stop="resetImagePicker"
                          style="z-index: 1"
                          color="red white--text"
                        >
                          <v-icon dark>close</v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 v-else>
                    <v-card>
                      <v-card-text>
                        <img
                          :src="form.image"
                          style="
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                    "
                        />
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <v-flex xs12>
                    <small>*indicates required field</small>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" outlined @click="store">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="form.dialog"
        persistent
        max-width="900px"
        v-if="!isEdit"
      >
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add Photo</span>
          </v-card-title>
          <v-container>
            <alert-message
              :extraSmall="true"
              :title="`Max 10 Images`"
              type="warning"
            >
              <template v-slot:message>
                Only 10 Images are allowed to upload at once
              </template>
            </alert-message>
          </v-container>
          <v-card-text class="pa-1">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex vx12 xs12>
                    <v-file-input
                      id="imageupload"
                      ref="files"
                      outlined
                      dense
                      :clearable="false"
                      v-on:change="handleFilesUpload"
                      multiple
                      label="Add Images"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-flex>
                </v-layout>

                <v-layout pl-3 pr-3 wrap>
                  <v-flex
                    xs4
                    v-for="(v, key) in previewImage"
                    :key="key"
                    v-on:change="pushData(v, key)"
                  >
                    <v-card class="mx-auto" max-width="400">
                      <v-img
                        id="uploadPhoto"
                        class="white--text align-end"
                        height="200px"
                        :src="v.image"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-text-field
                          style="margin-bottom: 0"
                          label="Photo Title*"
                          autocomplete="off"
                          required
                          class="pa-0"
                          name="name"
                          outlined
                          dense
                          v-model="v.imageTitle"
                        ></v-text-field>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  @click="(form.dialog = false), form.reset(), clearfields()"
                  >Close</v-btn
                >
                <v-btn color="success" outlined @click="store1" v-if="!isEdit"
                  >Save</v-btn
                >
                <v-btn color="success" outlined @click="update" v-else
                  >Update</v-btn
                >
              </v-card-actions>
            </v-form>

            <v-overlay :value="showProgressBar">
              <div style="width: 400px; height: 50px">
                <h2>Uploading..</h2>
                <p class="mb-5" style="color: #e9e9e9">
                  Please wait while your file is being uploaded....
                </p>
                <v-progress-linear
                  background-color="white"
                  :value="value"
                ></v-progress-linear>
              </div>
            </v-overlay>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";
import { mapState } from "vuex";
import Form from "@/library/Form";
import ImageCompressor from "image-compressor.js";
let typingTimer;
let doneTypingInterval = 400;
export default {
  mixins: [Mixins],
  data: () => ({
    error: false,
    isEdit: false,
    multipleFiles: [],
    titleArray: [],
    previewImage: [],
    form: new Form(
      {
        title: "",
        description: "",
        image: "",
        albums: "",
        multipleImages: [],
      },
      "/api/photo"
    ),
    search: null,
    image_search: "",

    imagePicker: {
      name: null,
      image: null,
      url: null,
    },
    album_name: "",
    uploadPercentage: 0,
    value: 10,
    bufferValue: 20,
    interval: 0,
    showProgressBar: false,
    headers: [
      {
        text: "#",
        align: "center",
        value: "id",
        width: 10,
        sortable: false,
      },
      {
        text: "Title ",
        align: "left",
        width: 200,
        value: "title",
        sortable: false,
      },
      {
        text: "Image ",
        align: "left",
        width: 200,
        value: "image",
        sortable: false,
      },
      { text: "Action", align: "right", width: 200, sortable: false },
    ],
    image_preview: false,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      this.get();
    },
    // 'pagination.page': function () {
    //     this.get();
    // },
    value(val) {
      if (val < 100) return;

      this.value = 0;
      this.bufferValue = 10;
      this.startBuffer();
    },
    image_search(val) {
      if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.get();
      }, doneTypingInterval);
    },
  },
  mounted() {
    this.get();
  },

  methods: {
    imgae_preview() {
      this.image_preview = true;
    },
    clearfields() {
      this.$refs.form.reset();
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.value += Math.random() * (15 - 5) + 5;
        this.bufferValue += Math.random() * (15 - 5) + 6;
      }, 2000);
    },
    update() {
      const formData = new FormData();
      Object.keys(this.form.originalData).map((keys) => {
        formData.append(keys, this.form.originalData[keys]);
      });
      Array.from(this.multipleFiles).forEach((file) => {
        formData.append("multipleImages[]", file);
      });
      formData.append("title", this.form.title);
      formData.append("imageTitle", JSON.stringify(this.titleArray));
      formData.append("image", this.imagePicker.image);
      this.$rest
        .put("/api/photo/" + this.form.id)
        .then((response) => {})
        .catch((error) => {});
    },
    setData() {
      this.isEdit = true;
      this.previewImage.push({ image: this.form.image });
      this.titleArray.push({
        name: this.form.title,
      });
    },
    pushData(value, i) {
      this.titleArray.push({
        name: value.imageTitle,
      });
    },
    // queryString() {
    //     let json = this.pagination;
    //     return '?' +
    //         Object.keys(json).map(function (key) {
    //             if (![null, undefined].includes(json[key]))
    //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    //         }).join('&') + '&albumId=' + this.$route.params.albumId;
    // },

    get(params) {
      let extraparams =
        "&albumId=" +
        this.$route.params.albumId +
        "&search_query=" +
        this.image_search;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        // this.pagination.totalItems = data.meta.total;
        this.album_name = data.album_name;
      });
    },

    store() {
      this.form.image = this.imagePicker.image;
      this.form.albums = this.$route.params.albumId;
      this.form.multipleImages = this.multipleFiles;
      this.form.store().then(() => {
        this.resetImagePicker();
        this.get();
      });
    },
    store1() {
      if (this.multipleFiles.length > 0) {
        this.previewImage.forEach((value, index) => {
          if (value.imageTitle == "") {
            this.error = true;
          }
        });
        if (!this.error) {
          this.showProgressBar = true;
          const formData = new FormData();
          Object.keys(this.form.originalData).map((keys) => {
            formData.append(keys, this.form.originalData[keys]);
          });
          Array.from(this.multipleFiles).forEach((file) => {
            formData.append("multipleImages[]", file);
          });
          formData.append("title", this.form.title);
          // formData.append('imageTitle',JSON.stringify(this.titleArray));
          formData.append("imageTitle", JSON.stringify(this.previewImage));
          formData.append("image", this.imagePicker.image);
          formData.append("albums", this.$route.params.albumId);

          this.startBuffer();
          this.$rest
            .post("/api/photo", formData, {
              onUploadProgress: function(progressEvent) {
                this.uploadPercentage = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            })
            .then((response) => {
              if ((this.uploadPercentage = 100)) {
                clearInterval(this.interval);
                if (response.status == 200) {
                  this.form.dialog = false;
                  this.multipleFiles = [];
                  this.titleArray = [];
                  this.previewImage = [];
                  this.clearfields();
                  this.get();
                }
              }
            })
            .finally(() => {
              clearInterval(this.interval);
              this.showProgressBar = false;
            });
        } else {
          this.$events.fire("notification", {
            message: "Please fill all the title",
            status: "error",
          });
          this.error = false;
        }
      } else {
        this.$events.fire("notification", {
          message: "Please Select At least one image",
          status: "error",
        });
      }
    },
    onFilePicked(e) {
      const files = e.target.files;
      let fileName = files[0].name;
      if (fileName.lastIndexOf(".") <= 0) {
        this.$store.dispatch(
          "notifyError",
          "Please upload a valid image file!"
        );
        return;
      }
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.addEventListener("load", () => {
        this.imagePicker.url = fileReader.result;
        this.imagePicker.name = fileName;

        const imageCompressor = new ImageCompressor();
        imageCompressor
          .compress(files[0], { quality: 0.5 })
          .then((result) => {
            this.imagePicker.image = result;
          })
          .catch((err) => {
            // Handle the error
          });
      });
    },
    resetImagePicker() {
      this.imagePicker = {
        url: undefined,
        image: undefined,
        name: undefined,
      };
      this.$refs.image.value = "";
    },

    /**
     * Image Upload Handler
     */
    onPickFile() {
      this.$refs.image.click();
    },

    handleFilesUpload(files) {
      let imageArray = [];
      let titleArray = [];
      let compressedImage = [];
      Object.keys(files).forEach(function(key) {
        imageArray.push({
          image: URL.createObjectURL(files[key]),
          imageTitle: files[key].name,
        });
        const imageCompressor = new ImageCompressor();
        imageCompressor
          .compress(files[key], { quality: 0.3 })
          .then((result) => {
            compressedImage.push(result);
          })
          .catch((err) => {});
      });
      if (imageArray.length <= 10) {
        this.titleArray = titleArray;
        this.previewImage = imageArray;
        this.multipleFiles = compressedImage;
      } else {
        this.previewImage = [];
        this.multipleFiles = [];
        this.$refs.form.reset();
        this.$events.fire("notification", {
          message: "You Can only select up to 10 images",
          status: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.album_style_mis {
  padding: 0 20px;
  width: 50% !important;

  h3 {
    font-size: 17px !important;
    border-bottom: 1px solid white;
  }

  p {
    font-size: 15px;
  }
}

.container.grid-list-md .layout .flex {
  margin-bottom: 40px;
}

.v-btn--bottom.v-btn--absolute.v-btn--small.edit {
  right: 60px;
}
</style>
